import en from '../lang/en.json'
import fr from '../lang/fr.json'
import enOverride from '../lang/override.en.json'
import frOverride from '../lang/override.fr.json'

const getMessages = (locale, groupId = null) => {
  if (locale === 'fr') {
    if (groupId && frOverride[groupId]) {
      return {
        ...en,
        ...fr,
        ...frOverride['b2b-overrides'],
        ...frOverride[groupId],
      }
    }
    return { ...en, ...fr }
  }

  if (groupId && enOverride[groupId]) {
    return { ...en, ...enOverride['b2b-overrides'], ...enOverride[groupId] }
  }

  return en
}

export default getMessages
